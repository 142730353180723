import React from "react";
import { Trans, Link } from "gatsby-plugin-react-i18next";

import nestlePurinaDepot from "../images/nestle-purina-depot.jpg";

const FeaturedBusinessCaseBlock = () => {
  return (
    <div className="fl-grid">
      <div className="fl-pan fl-pan--business">
        <img className="fl-pan-bg" src={nestlePurinaDepot} alt="" loading="lazy" />
        <div className="fl-inline-three-blocks">
          <div className="fl-green-context">
            <div className="fl-tag">
              <Trans i18nKey="shippers.business-case.tag" />
            </div>
            <h2>
              <Trans i18nKey="shippers.business-case.title" />
            </h2>
            <p>
              <Trans i18nKey="shippers.business-case.subtitle" />
            </p>
          </div>
          <blockquote className="fl-quote">
            <Trans parent="p" i18nKey="shippers.business-case.quotes.first.content" />
            <p className="fl-quote-author">
              <Trans i18nKey="shippers.business-case.quotes.first.author" />,
            </p>
            <p className="fl-quote-job-title">
              <Trans i18nKey="shippers.business-case.quotes.first.job-title" />
            </p>
          </blockquote>
          <blockquote className="fl-quote">
            <Trans parent="p" i18nKey="shippers.business-case.quotes.second.content" />
            <p className="fl-quote-author">
              <Trans i18nKey="shippers.business-case.quotes.second.author" />,
            </p>
            <p className="fl-quote-job-title">
              <Trans i18nKey="shippers.business-case.quotes.second.job-title" />
            </p>
          </blockquote>
        </div>
        <div className="fl-inline-three-blocks fl-blue-context" style={{ marginTop: "40px" }}>
          <div className="fl-quick-fact">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="shippers.business-case.quick-facts.first.tag" />
            </div>
            <code>
              <Trans i18nKey="shippers.business-case.quick-facts.first.fact" />
            </code>
            <Trans parent="p" i18nKey="shippers.business-case.quick-facts.first.label" />
          </div>
          <div className="fl-quick-fact">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="shippers.business-case.quick-facts.second.tag" />
            </div>
            <code>
              <Trans i18nKey="shippers.business-case.quick-facts.second.fact" />
            </code>
            <Trans parent="p" i18nKey="shippers.business-case.quick-facts.second.label" />
          </div>
          <div className="fl-quick-fact">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="shippers.business-case.quick-facts.third.tag" />
            </div>
            <code>
              <Trans i18nKey="shippers.business-case.quick-facts.third.fact" />
            </code>
            <Trans parent="p" i18nKey="shippers.business-case.quick-facts.third.label" />
          </div>
        </div>
        <p className="fl-link-next" style={{ marginTop: "40px", marginBottom: 0 }}>
          <Link to="/business-cases/nestle-purina/">
            <Trans i18nKey="shippers.business-case.read-more" />
          </Link>
        </p>
      </div>
    </div>
  );
};

export default FeaturedBusinessCaseBlock;

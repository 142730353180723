import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import FeaturedBusinessCaseBlock from "../components/FeaturedBusinessCaseBlock.js";
import BusinessCaseLink from "../components/BusinessCaseLink";

const BusinessCasesPage = ({
  data: {
    businessCases: { nodes: pages },
    postThumbnails: { nodes: thumbnails },
  },
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <SEO title={t("business-cases.html-title")} />
      <div className="fl-bg-bubble" style={{ left: "calc(50% - 552px - 415px)", top: "-134px" }} />
      <div
        className="fl-grid fl-top-element fl-blue-context"
        style={{ marginTop: "150px", marginBottom: "120px" }}>
        <div className="fl-first-third">
          <h1 className="fl-big-header">
            <Trans i18nKey="business-cases.headers.title" />
          </h1>
        </div>
        <div className="fl-second-half fl-big-block" style={{ alignSelf: "flex-start" }}>
          <h2>
            <Trans i18nKey="business-cases.headers.p-they-talk-about-our-relationship" />
          </h2>
        </div>
      </div>

      {i18n.language === "fr" ? <FeaturedBusinessCaseBlock /> : undefined}

      <div className="fl-lighter-blue-wrapper" style={{ marginTop: "104px" }}>
        <div className="fl-grid" style={{ marginTop: "104px", marginBottom: "137px" }}>
          <div className="fl-all-grid-width fl-business-cases-list">
            {pages.reverse().map(page => {
              const thumbnail = thumbnails.find(
                thNode => thNode.fileAbsolutePath === page.context.mdxFileAbsolutePath
              );

              return <BusinessCaseLink key={page.path} pageNode={page} thumbnail={thumbnail} />;
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BusinessCasesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    businessCases: allSitePage(
      filter: { path: { regex: "/business-cases/.+/" }, context: { language: { eq: $language } } }
    ) {
      nodes {
        path
        context {
          frontmatter {
            catchPhrase
            contactName
            companyName
          }
          mdxFileAbsolutePath
        }
      }
    }
    postThumbnails: allMdx(filter: { fileAbsolutePath: { regex: "/business-cases/.+/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          postThumbnail {
            publicURL
          }
        }
      }
    }
  }
`;

import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

const BusinessCaseLink = ({ pageNode, thumbnail }) => {
  const { t } = useTranslation();
  const matter = pageNode.context.frontmatter;

  return (
    <Link to={pageNode.path.replace(/^\/[a-z]{2}\//, "/")} className="fl-business-case fl-img-link">
      <div className="fl-illustration" data-link-click={t("business-cases.read-testimony")}>
        {thumbnail?.frontmatter?.postThumbnail ? (
          <img src={thumbnail.frontmatter.postThumbnail.publicURL} alt="" loading="lazy" />
        ) : null}
      </div>
      <p className="fl-business-case-hero">
        {matter.contactName}, {matter.companyName}
      </p>
      <p className="fl-business-case-title">{matter.catchPhrase}</p>
    </Link>
  );
};

export default BusinessCaseLink;
